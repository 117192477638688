<template>
  <div class="login-page">
    <div class="login-container">
      <div class="login-logo-container">
        <img
          class="mb-4"
          :src="require('../assets/taleon-logo-2.png')"
          width="350px"
        />
      </div>
      <v-form v-model="valid">
        <v-text-field
          v-model="username"
          append-icon="mdi-account-circle"
          label="Kullanıcı Adı"
          required
          outlined
        ></v-text-field>
        <v-text-field
          v-model="password"
          :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
          :rules="passwordRules"
          :type="showPassword ? 'text' : 'password'"
          label="Şifre"
          required
          outlined
          @click:append="showPassword = !showPassword"
        ></v-text-field>
        <v-btn
          block
          elevation="3"
          :disabled="!valid"
          color="primary"
          large
          @click="onLogin"
        >
          GİRİŞ YAP
        </v-btn>
      </v-form>
    </div>
  </div>
</template>

<script>
import axios from '@/axios';
import router from '@/router';

export default {
  name: 'Home',
  data: () => {
    return {
      valid: false,
      showPassword: false,
      rememberMe: false,
      username: '',
      password: '',
      passwordRules: [
        (v) => !!v || 'Şifre zorunlu',
        (v) => v.length >= 4 || 'Şifre en az 4 karakter olmalı'
      ]
    };
  },
  methods: {
    onLogin() {
      axios
        .post('/sign-in', {
          username: this.username,
          password: this.password
        })
        .then((response) => {
          localStorage.setItem('token', response.data.token);
          const userInfo = {
            name: response.data.name,
            lastname: response.data.lastname
          };
          localStorage.setItem('userInfo', JSON.stringify(userInfo));
          if (localStorage.token) {
            router.push('/');
          } else {
            this.$toast.error('API Error');
          }
        })
        .catch((err) => {
          if (err?.response?.status === 400) {
            this.$toast.error('Invalid login info');
          } else {
            this.$toast.error(err.message);
          }
        });
    }
  }
};
</script>

<style lang="scss">
@import '@style/page/login';
</style>
